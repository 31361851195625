<template>
  <transition
    appear
    enter-active-class="transition ease-out duration-300"
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class=""
    leave-class="opacity-100"
    leave-to-class="opacity-0"
    mode="out-in"
  >
    <div class="flex flex-wrap lg:flex-no-wrap">
      <div class="w-full lg:mx-5 my-5 lg:my-0 ">
        <GuestLinkedAccounts />
      </div>
    </div>
  </transition>
</template>

<script>
  import GuestLinkedAccounts from "@/components/details/guest/GuestLinkedAccounts"


  export default {
    name: "GuestSocialLinks",
    components: { GuestLinkedAccounts },
    data() {
      return {
        loading: false,
      }
    },

    mounted() {
      this.$store.commit('register/setCurrentStep', 6)
    }
  }
</script>

<style scoped>

</style>
